import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import UserLeft from '../PersonUserLeft.vue'
import page from '../../../components/page.vue'
import NoData from '../../../components/NoData.vue'
import CheckInService from '../../../api/CheckInService.js'

export default{
	name:'UserCollection',
	data(){
		return{
			pageIndex:1,
			pageSize:10,
			curType:0,
			collectionList:[],
			pageCount:0
		}
	},
	methods:{
		GetUserColletionList(){ //获取收藏列表
			var that = this
			if(that.curType == 0){ //词条
				CheckInService.GetUserColletionElementList(that.pageIndex,that.pageSize,that.$UserID,).then(res=>{
					if(res.Tag == 1){
						that.collectionList = res.Result
						that.pageCount = res.TotalCount
					}
				})
			}else if(that.curType == 1){ //条目
				CheckInService.GetUserColletionEntryList(that.pageIndex,that.pageSize,that.$UserID,).then(res=>{
					if(res.Tag == 1){
						that.collectionList = res.Result
						that.pageCount = res.TotalCount
					}
				})
			}else if(that.curType == 2){ //图书
				CheckInService.GetUserColletionBookList(that.pageIndex,that.pageSize,that.$UserID,).then(res=>{
					if(res.Tag == 1){
						that.collectionList = res.Result
						that.pageCount = res.TotalCount
					}
				})
			}else if(that.curType == 3){ //图片
				CheckInService.GetUserColletionImageList(that.pageIndex,that.pageSize,that.$UserID,).then(res=>{
					if(res.Tag == 1){
						that.collectionList = res.Result
						that.pageCount = res.TotalCount
					}
				})
			}else if(that.curType == 4){ //期刊
				CheckInService.GetUserColletionJournalList(that.pageIndex,that.pageSize,that.$UserID,).then(res=>{
					if(res.Tag == 1){
						that.collectionList = res.Result
						that.pageCount = res.TotalCount
					}
				})
			}
		},
		pageChange(page){
			this.pageIndex = page
			this.GetUserColletionList()
			window.scrollTo(0,0)
		},
		changeType(type){
			this.curType = type
			this.pageIndex = 1
			this.pageCount = 0
			this.GetUserColletionList()
		},
		cancelCollect(id){ //取消收藏
			var that = this
			CheckInService.UpdateUserColletion(that.$UserID,id).then(res=>{
				if(res.Tag == 1){
					that.GetUserColletionList()
				}else{
					that.$alert(res.Message, '提示', {
						confirmButtonText: '确定'
					});
				}
			})
		},
		toElementDetail(id){ //词条详情
			this.$router.push({
				path:'ElementDetail',
				query:{
					id:id
				}
			})
			// window.open(this.$WebUrl + 'ElementDetail?id=' + id)
		},
		toBookDetail(id){ //图书详情
			this.$router.push({
				path:'BookDetail',
				query:{
					id:id
				}
			})
			// window.open(this.$WebUrl + 'BookDetail?id=' + id)
		},
		toImageDetail(id){ //图片详情
			this.$router.push({
				path:'ImageDetail',
				query:{
					id:id
				}
			})
			// window.open(this.$WebUrl + 'ImageDetail?id=' + id)
		},
		toEntryDetail(id){ //条目详情
			this.$router.push({
				path:'EntryDetail',
				query:{
					id:id
				}
			})
			// window.open(this.$WebUrl + 'EntryDetail?id=' + id)
		},
		toJournalDetail(id){ //期刊详情
			this.$router.push({
				path:'JournalManagerDetail',
				query:{
					id:id
				}
			})
			// window.open(this.$WebUrl + 'JournalManagerDetail?id=' + id)
		}
	},
	mounted(){
		this.GetUserColletionList()
	},
	components:{
		'Header': Header,
		'Footer':Footer,
		'UserLeft':UserLeft,
		'page':page,
		'NoData':NoData
	}
}